<template>
  <div class="w_auto">
    <el-card class="mt15">
      <div slot="header" class="clearfix">
        <span style="font-size: 21px;">用户注册协议</span>
      </div>
      <div v-for="o in 4" :key="o" class="content">
        <h3>一、注册协议条款的确认和接受</h3>
        <p> 在线培训平台(以下亦称“本网站”)同意按照本协议的规定及其不定时发布的操作规则提供基于互联网和移动互联网的相关服务(以下称“网络服务”)。 </p>
        <p> 为获得网络服务, 申请人应当认真阅读、充分理解本《协议》中各条款, 包括免除或者限制在线培训平台责任的免责条款及对用户的权利限制条款。审慎阅读并选择接受或不接受本《协议》(未成年人应在法定监护人陪同下阅读)。 </p>
        <p> 同意接受本协议的全部条款的, 申请人应当按照页面上的提示完成全部的注册程序, 并在注册程序过程中点击“同意”按钮, 否则视为不接受本《协议》全部条款, 申请人应当终止并退出申请。 </p>
        <p> 本《协议》可由在线培训平台定期更新, 更新后的协议条款一旦公布即代替原来的协议条款, 恕不再另行通知, 用户可在在线培训平台查阅最新版协议条款。在在线培训平台修改《协议》条款后, 如果用户不接受修改后的条款, 请立即停止使用在线培训平台提供的网络服务, 继续使用的用户将被视为已接受了修改后的协议。 </p>
        <h3>二、服务内容</h3>
        <p> 1、网络服务的具体内容由在线培训平台根据实际情况提供, 包括学习资讯、学习工具、在线培训平台社群、在线培训平台问答、在线培训平台直播、在线培训平台网校、在线培训平台中小学、在线培训平台国际教育、在线培训平台考研集训营应用等服务。 </p>
        <p> 2、在线培训平台提供的网络服务包括收费和免费。收费服务包括但不限于在线培训平台网、在线培训平台网校和APP的部分收费课程, 用户使用收费网络服务需要支付约定费用。对于收费服务, 在线培训平台会在用户使用之前给予用户明确的提示, 只有用户根据提示确认同意支付相关费用, 用户才能使用该等收费服务。如用户未支付相关费用, 则在线培训平台有权不向用户提供该等收费服务。 </p>
        <p> 3、用户理解, 在线培训平台仅提供在线培训平台明确承诺的网络服务, 除此之外与相关网络服务有关的设备(如个人电脑、手机、及其他与接入互联网或移动网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费、为使用移动网络而支付的手机费)均应由用户自行负担。 </p>
        <p> 4、用户理解，在线培训平台网/在线培训平台网校/在线培训平台直播平台提供并提请用户在购买课程前先完整试听。用户可按《在线培训平台网校退班政策说明》除特别说明外，包括但不限于PC端和移动端下载课件在内的所有服务均附期限，到期终止。用户应在截止日期前享受其购买的服务。 </p>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.mt15 {
    margin: 15px 0 50px;
}
.content h3{
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.content p{
  font-size: 14px;
}
</style>